class BulldeskFormIntegration {
  constructor(settings) {
    if (!settings?.token) {
      console.log('Bulldesk: Token não identificado.');

      settings?.fail?.();

      return;
    }

    window.BulldeskSettings = window.BulldeskSettings || {};
    window.BulldeskSettings.formIsBusy = false;

    this.form = null;
    this.busy = false;
    this.settings = settings;
    this.fields = {
      phone: ['phone', 'telefone', 'tel'],
      name: ['nome', 'name', 'your-name'],
      document: ['document', 'cpf', 'cnpj'],
      email: [
        'email',
        'e-mail',
        'e_mail',
        'email_lead',
        'your-email',
        'form-field-email',
        'form_fields[email]',
      ],
      reject: [
        'captcha',
        '_wpcf7',
        '_wpcf7_version',
        '_wpcf7_unit_tag',
        '_wpnonce',
        '_wpcf7_is_ajax_call',
        '_wpcf7_locale',
        'password',
        'senha',
      ],
    };

    if (typeof this.settings.domain === 'undefined') {
      this.settings.domain = 'https://api.salesflowcrm.com.br';
    }

    if (typeof this.settings.map === 'undefined') {
      this.settings.map = {};
    }

    if (typeof this.settings.validate === 'undefined') {
      this.settings.validate = false;
    }

    if (typeof this.settings.success === 'undefined') {
      this.settings.success = null;
    }

    if (typeof this.settings.mapCallback != 'function') {
      this.settings.mapCallback = (fields) => fields;
    }

    if (typeof this.settings.fail === 'undefined') {
      this.settings.fail = null;
    }

    if (typeof this.settings.dom === 'undefined') {
      this.settings.dom = document;
    }

    if (this.settings.fb_pixel_id) {
      this.loadFacebookPixel();
    }

    this.withjQuery(() => {
      jQuery(this.settings.dom).ready(this.bindSubmits());
    });
  }

  loadFacebookPixel() {
    if (typeof fbq === 'undefined') {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js',
      );

      fbq('init', this.settings.fb_pixel_id);
    }

    fbq('track', 'PageView', {
      content_name: this.settings.identifier,
      content_category: this.settings.identifier,
    });
  }

  withjQuery(callback) {
    if (
      typeof window.jQuery === 'undefined' ||
      typeof window.jQuery.fn.on === 'undefined'
    ) {
      this.load(
        'https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.1/jquery.min.js',
        callback,
      );
    } else {
      callback();
    }
  }

  load(source, callback) {
    const head = this.settings.dom.getElementsByTagName('head')[0];
    const script = this.settings.dom.createElement('script');

    script.type = 'text/javascript';
    script.src = source;
    // most browsers
    script.onload = callback;
    // IE 6 & 7
    script.onreadystatechange = function () {
      if (this.readyState === 'complete') {
        callback();
      }
    };
    head.appendChild(script);
  }

  bindSubmits() {
    // window.addEventListener('load', () => {
    if (typeof this.settings.selector !== 'undefined') {
      // When user pass a selector
      const button = jQuery(this.settings.dom).find(this.settings.selector);

      let onclick;
      // Save onclick for later
      try {
        onclick = button[0].onclick;

        if (button[0] && onclick) {
          button[0].onclick = function () {};
        }
      } catch (e) {
        //
      }

      button
        .off('.bulldesk')
        .on('click.bulldesk', this.getBinding(this.settings.selector, onclick));
    } else {
      // Normal submits
      jQuery(':submit')
        .off('.bulldesk.')
        .on('click.bulldesk', this.getBinding(':submit'));
    }
    // });
  }

  getBinding(selector, onclick) {
    const me = this;

    return function (event) {
      event.preventDefault();

      if (
        me.busy ||
        (typeof me.settings.selector == 'undefined' &&
          window.BulldeskSettings.formIsBusy)
      ) {
        console.warn('Form already running.');

        return;
      }

      me.form = me.getForm(event.target);

      if (!me.form.length) {
        console.error('Form not found.');

        return;
      }

      const button = me.form.find(selector);
      const skip = me.form.attr('data-bulldesk-skip');

      console.log(button.attr('data-sitekey'));

      const inputs = me.getFormData();

      if (!inputs) {
        return;
      }

      try {
        const ids = JSON.parse(atob(button.attr('data-sitekey')));

        if (ids && ids['bulldesk-form-id']) {
          inputs.push({
            name: 'bulldesk-form-id',
            value: ids['bulldesk-form-id'],
          });
        }

        if (ids && ids['bulldesk-page-id']) {
          inputs.push({
            name: 'bulldesk-page-id',
            value: ids['bulldesk-page-id'],
          });
        }

        // if (ids && ids['bulldesk-conversion-identifier']) {
        //   inputs.push({ name: 'identifier', value: ids['bulldesk-conversion-identifier'] });
        // }

        console.log(inputs);
      } catch (e) {
        console.warn(e);
      }

      if (
        !me.hasAnyRequiredField(inputs) ||
        (button.length && typeof skip !== 'undefined' && skip !== false)
      ) {
        button.off('.bulldesk');

        if (me.isActionValid(me.form)) {
          me.submitForm(me.form, button);
        } else {
          button.click();
        }

        console.log('Skipping bulldesk form integration.');

        return;
      }

      let valid = true;

      if (me.settings.validate) {
        // Remove error messages
        me.form.find('.bulldesk-error-message').remove();

        me.form.find(':input').each((i, input) => {
          if (typeof input.validity !== 'object' || input.validity.valid) {
            return;
          }

          valid = false;

          me.form
            .find(input)
            .after(
              `<div class="bulldesk-error-message">${input.validationMessage}</div>`,
            );
        });

        if (!me.hasAnyRequiredFieldFilled(inputs)) {
          valid = false;

          console.warn('Fields not filled.');
        }

        // Keep
        if (
          typeof me.form[0].checkValidity === 'function' &&
          !me.form[0].checkValidity()
        ) {
          valid = false;
        }
      }

      if (!valid) {
        console.warn('Validation failed.');

        return false;
      }

      if (me.settings.disable) {
        button.attr('disabled', 'disabled');
      }

      // Convert
      try {
        fbq('track', 'Lead', {
          content_name: me.settings.identifier,
          content_category: me.settings.identifier,
        });
      } catch (e) {
        // console.warn(e);
      }

      me.post(inputs, (response) => {
        // Unbind the .bulldesk events
        button.off('.bulldesk');

        if (me.settings.disable) {
          button.prop('disabled', false);
          button.removeAttr('disabled');
        }

        try {
          if (button && button[0] && typeof onclick !== 'undefined') {
            button[0].onclick = onclick;
          }
        } catch (e) {
          //
        }

        // Data for bee form conversions
        let action = button.attr('data-action');
        let action_content = button.attr('data-callback');

        console.log(response);

        if (
          response.form &&
          response.form.action &&
          response.form.action_content
        ) {
          action = response.form.action;
          action_content =
            response.form.compiled_action_content ||
            response.form.action_content;
        }
        console.log(action);
        console.log(action_content);

        // Call callback or submit form
        if (me.hasSuccessCallback()) {
          me.settings.success(response, me.form, inputs);

          if (me.isActionValid(me.form)) {
            me.submitForm(me.form, button);
          } else {
            button.click();
          }
        } else if (me.isActionValid(me.form)) {
          me.submitForm(me.form, button);
        } else if (action) {
          if (action === 'redirect' && action_content.startsWith('http')) {
            window.location.href = action_content;
          } else {
            me.form.html(
              action_content || 'Obrigado! Em breve entraremos em contato.',
            );
          }
        } else {
          button.click();
        }

        me.form = null;

        // Rebind submit after done
        setTimeout(() => {
          try {
            me.bindSubmits();
          } catch (e) {
            console.warn(e);
          }
        }, 1000);
      }, selector);

      return false;
    };
  }

  submitForm(form, button = null) {
    if (form.attr('method') == 'html') {
      form.html(form.attr('data-html-action') || 'Obrigado!');

      return;
    }

    // let action = form.attr('action');

    // if (! action.startsWith('http') && Strings.isDomain(action)) {
    //   form.attr('action', '//' + action);
    // }

    if (button && button.click && typeof button.click === 'function') {
      button.click();
      return;
    }

    form.submit();
  }

  getForm(button) {
    let form;
    const matches = ['form', '#hb-fields-form', '.newsletter', '.form', 'div'];

    for (let i = 0; i < matches.length; i++) {
      form = jQuery(button).closest(matches[i]);

      if (form.length) {
        return form;
      }
    }
  }

  getFormData() {
    const me = this;

    let inputs = this.form.find(':input');

    inputs = inputs.map(function () {
      if (me.isFieldAllowed(this)) {
        return this;
      }
    });

    return this.map(inputs);
  }

  map(inputs) {
    const checkboxInputs = this.identifyCheckboxTypeAndCount(inputs); 
    const cloned = [];

    for (let i = 0; i < inputs.length; i++) {
      const current = inputs[i];

      // Skip radio inputs not checked
      if (current.type === 'radio' && !jQuery(current).is(':checked')) {
        continue;
      }
      // Skip checkbox inputs not checked
      if (current.type === 'checkbox' && !jQuery(current).is(':checked')) {
        continue;
      }
      
      let isCheckboxTypeUnique = false;
      if(current.type === 'checkbox') {
        isCheckboxTypeUnique = checkboxInputs[current.name].count < 2;
      }

      if (typeof this.settings.map[current.name] !== 'undefined') {
        cloned.push({
          name: this.settings.map[current.name],
          value: current.value,
          type: isCheckboxTypeUnique ? `${current.type}-unique` : current.type
        });
      } else if (typeof this.settings.map[current.id] !== 'undefined') {
        cloned.push({
          name: this.settings.map[current.id],
          value: current.value,
          type: isCheckboxTypeUnique ? `${current.type}-unique` : current.type
        });
      } else {
        cloned.push({ 
          name: current.name || current.id,
          value: current.value,
          type: isCheckboxTypeUnique ? `${current.type}-unique` : current.type
        });
      }
    }

    try {
      return this.settings.mapCallback(cloned);
    } catch (e) {
      console.warn(e);
      console.warn('Error on mapping data, skipping integration');

      return null;
    }
  }

  identifyCheckboxTypeAndCount(inputs) {
    const checkboxMap = {};
    inputs.each((_, input) => {
      if (input.type === 'checkbox') {
        if (!checkboxMap[input.name]) {
          checkboxMap[input.name] = {
            count: 0,
            values: []
          };
        }
        checkboxMap[input.name].count += 1;
        checkboxMap[input.name].values.push(input);
      }
    });
    return checkboxMap;
  }
 
  isEmail(element) {
    return this.fields.email.indexOf(element.name.toLowerCase()) > -1;
  }

  isName(element) {
    return this.fields.name.indexOf(element.name.toLowerCase()) > -1;
  }

  isPhone(element) {
    return this.fields.phone.indexOf(element.name.toLowerCase()) > -1;
  }

  isDocument(element) {
    return this.fields.document.indexOf(element.name.toLowerCase()) > -1;
  }

  hasEmail(inputs) {
    const me = this;
    let has = false;

    jQuery.each(inputs, function () {
      if (me.isEmail(this)) {
        has = true;
      }
    });

    return has;
  }

  isEmailFilled(inputs) {
    const me = this;
    let has = false;

    jQuery.each(inputs, function () {
      if (me.isEmail(this) && this.value.length) {
        has = true;
      }
    });

    return has;
  }

  hasAnyRequiredField(inputs) {
    const me = this;
    let has = false;

    jQuery.each(inputs, function () {
      if (
        me.isEmail(this) ||
        me.isName(this) ||
        me.isPhone(this) ||
        me.isDocument(this)
      ) {
        has = true;
      }
    });

    return has;
  }

  hasAnyRequiredFieldFilled(inputs) {
    const me = this;
    let has = false;

    jQuery.each(inputs, function () {
      if (
        (me.isEmail(this) ||
          me.isName(this) ||
          me.isPhone(this) ||
          me.isDocument(this)) &&
        this.value.length
      ) {
        has = true;
      }
    });

    return has;
  }

  isFieldAllowed(element) {
    if (this.isEmail(element)) {
      return true;
    }

    if (this.isPassword(element) || this.isRejected(element)) {
      return false;
    }

    return true;
  }

  isPassword(element) {
    return jQuery(element).is(':password');
  }

  isRejected(element) {
    return this.fields.reject.indexOf(element.name.toLowerCase()) > -1;
  }

  isActionValid(form) {
    const action = form.attr('action');
    const method = form.attr('method');

    return (
      (typeof action !== 'undefined' &&
        action.trim() !== '' &&
        !action.trim().startsWith('/#') &&
        !action.trim().startsWith('#') &&
        typeof method !== 'undefined' &&
        method.trim() !== '') ||
      method === 'html'
    );
  }

  hasIdentifier(inputs) {
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name === 'identifier') {
        return true;
      }
    }

    return false;
  }

  getIdentifier() {
    if (
      typeof this.settings.identifier === 'undefined' ||
      this.settings.identifier === 'Formulário sem identificador'
    ) {
      const pathname = window.location.pathname.replace(/^\/|\/$/g, '');

      return pathname || 'Formulário sem identificador';
    }

    return this.settings.identifier;
  }

  getBaseDomain() {
    return window.location.hostname.replace('www.', '');
  }

  getLocalStorage(key) {
    const settings =
      JSON.parse(window.localStorage.getItem('bulldesk-settings')) || {};

    const { value = '', expiry = '' } = settings[key] || {};

    const now = new Date();

    if (!value || !expiry || now.getTime() > expiry) {
      return;
    }

    return value;
  }

  post(data, callback, selector) {
    // Add client id.
    try {
      data.push({
        name: 'salesflow-client',
        value: window.BulldeskSettings.client,
      });
    } catch (e) {
      console.warn('Bulldesk client not found.');
    }

    // Add token.
    data.push({
      name: 'token',
      value: this.settings.token,
    });

    // Add domain.
    data.push({
      name: 'salesflow-domain',
      value: this.getBaseDomain(),
    });

    if (!this.hasIdentifier(data)) {
      data.push({
        name: 'identifier',
        value: this.getIdentifier(),
      });
    }

    const deniedCookie = this.getLocalStorage('deniedCookie');

    if (deniedCookie) {
      console.log('deniedCookie');
      data.push({
        name: 'deniedCookie',
        value: true,
      });
    }

    const me = this;

    me.busy = true;
    window.BulldeskSettings.formIsBusy = true;

    const formData = this.prepareFormData(data);

    this.withjQuery(() => {
      jQuery.ajax({
        url: `${me.settings.domain}/conversion`,
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        success(response, status) {
          if (callback) {
            callback(response, status);
          }
        },
        error(response) {
          me.createToast('Há campos que devem ser preenchidos.')
  
          if (me.hasFailCallback()) {
            me.settings.fail(response);
          }
  
          const button = me.form.find(selector);
          button.prop('disabled', false);
          button.removeAttr('disabled');
        },
        complete() {
          setTimeout(() => {
            me.busy = false;
            window.BulldeskSettings.formIsBusy = false;
          }, 500);
        }
      });
    });
  }

  prepareFormData(data) {
    const formData = new FormData();
    data.forEach(item => {
      if(item.type === 'checkbox-unique') {
        item.name = item.name.replace("[]", "");
        formData.append(item.name, true);
        return;
      }
      if(Array.isArray(item.value)) {
        item.value.forEach(val => formData.append(item.name, val));
        return;
      }
      formData.append(item.name, item.value);
    });
    return formData;
  }

  hasFailCallback() {
    return typeof this.settings.fail === 'function';
  }

  hasSuccessCallback() {
    return typeof this.settings.success === 'function';
  }

  createToast(message) {
      // Create the toast container
      const toast = document.createElement('div');
      toast.style.position = 'fixed';
      toast.style.bottom = '20px';
      toast.style.right = '20px';
      toast.style.backgroundColor = '#ffffff';
      toast.style.color = 'black';
      toast.style.padding = '10px';
      toast.style.paddingTop = '15px';
      toast.style.paddingBottom = '15px';
      toast.style.borderRadius = '5px';
      toast.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
      toast.style.display = 'flex';
      toast.style.alignItems = 'center';
      toast.style.zIndex = '10000';

      // Create the close button
      const closeButton = document.createElement('button');
      closeButton.innerHTML = '&times;';
      closeButton.style.marginLeft = 'auto';
      closeButton.style.border = 'none';
      closeButton.style.backgroundColor = 'transparent';
      closeButton.style.cursor = 'pointer';
      closeButton.style.color = 'black';
      closeButton.style.fontSize = '20px';

      // Close toast event
      closeButton.onclick = function() {
          document.body.removeChild(toast);
      };

      // Create the icon
      const icon = document.createElement('div');
      icon.style.backgroundColor = 'red';
      icon.style.borderRadius = '50%';
      icon.style.width = '20px';
      icon.style.height = '20px';
      icon.style.display = 'flex';
      icon.style.justifyContent = 'center';
      icon.style.alignItems = 'center';
      icon.style.marginRight = '10px';
      icon.innerHTML = '<span style="color: white; font-size: 14px;">X</span>';

      // Create the error message
      const messageDiv = document.createElement('div');
      messageDiv.innerText = message;
      messageDiv.style.flexGrow = '1';

      // Append children
      toast.appendChild(icon);
      toast.appendChild(messageDiv);
      toast.appendChild(closeButton);

      // Append toast to body
      document.body.appendChild(toast);

      setTimeout(() => {
        document.body.removeChild(toast);
      }, 2500);
  }
}



window.BulldeskFormIntegration = BulldeskFormIntegration;

if (window.BulldeskFQ && window.BulldeskFQ.length) {
  for (let i = 0; i < window.BulldeskFQ.length; i++) {
    window.BulldeskFQ[i]();
    window.BulldeskFQ.splice(i, 1);
  }
}
